@media (max-width: 575px) {
  .container {
    padding-left: 12.53333333%;
    padding-right: 12.53333333%;
  }
}
body {
  color: #666;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  line-height: 2;
}

h1 {
  font-size: 1.25rem;
}

h2 {
  font-size: 1rem;
}

small {
  font-size: 65%;
}

a.silent {
  color: #666;
}
